import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import PostLink from "../components/post-link";
import SEO from "../components/seo";

const IndexPage = ({ data: { site, videos } }) => {
  const videoLinks = videos.edges.map((edge) => (
    <PostLink key={edge.node.id} post={edge.node} />
  ));

  const page = {
    fields: {
      slug: "/videos",
    },
  };
  return (
    <Layout>
      <SEO title="Videos" node={page} />
      <div className="mx-auto prose lg:prose-xl dark:prose-invert prose-a:text-indigo-600 dark:prose-a:text-indigo-400">
        <h1>Videos</h1>
        <div>{videoLinks}</div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    site: site {
      siteMetadata {
        title
        description
        author
        twitter
      }
    }
    videos: allMarkdownRemark(
      sort: { order: DESC, fields: [fields___date] }
      filter: { fields: { type: { eq: "video" } } }
    ) {
      edges {
        node {
          id
          fileAbsolutePath
          fields {
            slug
            date
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`;

export default IndexPage;
